<template>
    <div
        class="flex items-center justify-center"
        style="background-size: cover; min-height: 100vh"
        :style="`background-image: url(${require('@/assets/images/login-bg.jpg')});`"
    >
        <Alert />
        <div class="flex items-center justify-center mv4 w-90" style="background-color: white">
            <div class="w-60-l w-90">
                <div class="tc pv5">
                    <h1 style="color: #132c8c">Let's get you set up</h1>
                    <p style="color: #55596e; font-size: 18px">
                        Your business is our business!<br />
                        To get you started on the Simplebks journey, we'll need a few details from you.<br />
                        Don't worry - it'll be quick, easy, and maybe even fun!<br />
                        Let's do this!
                    </p>
                </div>
                <div class="pb5">
                    <form @submit.prevent="onSubmit">
                        <div class="flex flex-wrap justify-between">
                            <InputBox
                                :name="'orgName'"
                                :title="'Business Name *'"
                                :on-change="onChange"
                                :required="true"
                                :placeholder="'Enter your business name'"
                                style="width: -webkit-fill-available"
                            />

                            <!-- <InputBox
                                :name="'phoneNumber'"
                                :title="'Phone Number *'"
                                :on-change="onChange"
                                :required="true"
                                :placeholder="'Enter the phone number you want on your receipts/invoices'"
                                :type="'phone'"
                            /> -->
                            <div class="tel-div">
                                <div class="b pb2">Phone Number *</div>
                                <vue-tel-input
                                    :type="'tel'"
                                    :placeholder="'Enter your phone number'"
                                    :id="'phone'"
                                    class="form-sign2"
                                    :name="'phone'"
                                    mode="international"
                                    :defaultCountry="'NG'"
                                    :required="true"
                                    v-model="state.phoneNumber"
                                ></vue-tel-input>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 b">Business Email *</label>
                                <div class="input-group">
                                    <input
                                        class="form-sign w-100"
                                        v-model="state.orgEmail"
                                        @change="validateEmail"
                                        type="email"
                                        name="email"
                                        placeholder="Enter the email you want on your receipts/invoices"
                                        id="email"
                                    />
                                    <span v-if="state.isEmailValid && !state.isValidatingEmail" class="input-group-label">
                                        <font-awesome-icon icon="fa-solid fa-check" class="fa-2x text-success" />
                                    </span>
                                    <span
                                        v-else-if="state.isEmailValid === false && state.orgEmail !== ''"
                                        class="input-group-label"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-remove" class="fa-2x text-danger" />
                                    </span>
                                    <span v-else-if="state.isValidatingEmail" class="input-group-label">
                                        <font-awesome-icon icon="fa-solid fa-circle-notch" class="fa-2x fa-spin" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Type of business *</label>
                                <select class="form-sign" v-model="state.business" style="padding: 15px" required>
                                    <option value="">Select an option</option>
                                    <option value="Sole Trader/Business Name">Sole Trader/Business Name</option>
                                    <option value="Partnership">Partnership</option>
                                    <option value="Limited Liability Company">Limited Liability Company</option>
                                    <option value="Non Profit">Non Profit</option>
                                </select>
                            </div>

                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">What does your business do *</label>
                                <select class="form-sign" v-model="state.typeOfBusiness" style="padding: 15px" required>
                                    <option value="">Select what best describes your business</option>
                                    <option value="Accommodation and Food Services">Accommodation and Food Services</option>
                                    <option value="Administrative and Support Services">
                                        Administrative and Support Services
                                    </option>
                                    <option value="Arts and Recreation Services">Arts and Recreation Services</option>
                                    <option value="Construction/Builder">Construction/Builder</option>
                                    <option value="Creative Arts & Media Production">Creative Arts & Media Production</option>
                                    <option value="Education and Training">Education and Training</option>
                                    <option value="Farming, forestry and fishing">Farming, forestry and fishing</option>
                                    <option value="Fashion">Fashion</option>
                                    <option value="Financial services & insurance">Financial services & insurance</option>
                                    <option value="Manufacturing">Manufacturing</option>
                                    <option value="Medical / Health Care / Community services">
                                        Medical / Health Care / Community services
                                    </option>
                                    <option value="Personal, Beauty, Wellbeing and other services">
                                        Personal, Beauty, Wellbeing and other services
                                    </option>
                                    <option value="Professional Services (e.g. Legal, Accounting, Marketing, Consulting)">
                                        Professional Services (e.g. Legal, Accounting, Marketing, Consulting)
                                    </option>
                                    <option value="Property Operators and Real Estate services">
                                        Property Operators and Real Estate services
                                    </option>
                                    <option value="Rental & Hiring services (non Real Estate)">
                                        Rental & Hiring services (non Real Estate)
                                    </option>
                                    <option value="Repair and Maintenance (Automotive & Property)">
                                        Repair and Maintenance (Automotive & Property)
                                    </option>
                                    <option value="Retail Trade (Food & Beverage)">Retail Trade (Food & Beverage)</option>
                                    <option value="Retail Trade & Ecommerce (Non-Food)">
                                        Retail Trade & Ecommerce (Non-Food)
                                    </option>
                                    <option value="Technology / Telecommunications services">
                                        Technology / Telecommunications services
                                    </option>
                                    <option value="Trades work (e.g. Plumber, Carpenter, Electrician)">
                                        Trades work (e.g. Plumber, Carpenter, Electrician)
                                    </option>
                                    <option value="Transport, Logistics, Postal, Warehousing">
                                        Transport, Logistics, Postal, Warehousing
                                    </option>
                                    <option value="Wholesale Trade">Wholesale Trade</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>

                            <!-- <InputBox :name="''" :title="''" :on-change=""  :placeholder="'E.g Freelance'" :required="true" />-->
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Last day of financial year (optional)</label>

                                <input id="lastFinAccYear" class="form-sign" type="date" v-model="state.lastFinAccYear" />
                            </div>

                            <!-- <InputBox
                                :name="'lead'"
                                :title="'How did you hear about us'"
                                :on-change="onChange"
                                :placeholder="'E.g Facebook'"
                            /> -->
                            <SelectOption :name="'lead'" :title="'How did you hear about us'" :on-change="onChange">
                                <template v-slot:child>
                                    <option value="">Select an option</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Google Ads">Google Ads</option>
                                    <option value="Email">Email</option>
                                    <option value="Friends">Friends & Colleagues</option>
                                    <option value="Blog">Blog</option>
                                    <option value="Newsletter">Newsletter</option>
                                    <option value="Fliers">Fliers</option>
                                    <option value="Others">Others</option>
                                </template>
                            </SelectOption>
                        </div>
                        <!-- <div class="flex flex-wrap justify-between">
                            <InputBox
                                :name="'bankAccountName'"
                                :title="'Account Name'"
                                :on-change="onChange"
                                :placeholder="'Joe Meatpie'"
                                :type="'text'"
                            />
                            <InputBox
                                :name="'bankAccountNumber'"
                                :title="'Account Number'"
                                :on-change="onChange"
                                :placeholder="'0123456789'"
                                :type="'text'"
                            />
                        </div> -->
                        <!-- <div class="flex flex-wrap justify-between">
                            <InputBox
                                :name="'bankName'"
                                :title="'Bank'"
                                :on-change="onChange"
                                :placeholder="'ABC Bank'"
                                :type="'text'"
                            />
                            <InputBox
                                :name="'bankSortCode'"
                                :title="'Sort Code'"
                                :on-change="onChange"
                                :placeholder="'01-23-45'"
                                :type="'text'"
                            />
                        </div> -->
                        <div class="flex flex-wrap justify-between">
                            <SelectOption :name="'orgCategory'" :title="'Business Category'" :on-change="onChange">
                                <template v-slot:child>
                                    <option value="">Select an option</option>
                                    <option value="Service">I offer services</option>
                                    <option value="Product">I make or sell products</option>
                                </template>
                            </SelectOption>

                            <InputBox
                                :name="'noOfStaff'"
                                :title="'Number of Staff(s)'"
                                :on-change="onChange"
                                :placeholder="'Enter the number of staff in your business'"
                                :type="'number'"
                            />
                        </div>
                        <input type="hidden" v-model="state.plan" />
                        <input type="hidden" v-model="state.period" />

                        <button class="mt3 w-100 submit-btn" type="submit" :disabled="state.disableButton">
                            {{ state.disableButton ? 'Setting up your business, please wait...' : 'Continue' }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import moment from 'moment-timezone'
import InputBox from '@/components/FormComponents/InputBox'
import SelectOption from '@/components/FormComponents/SelectOption'
import Alert from '@/components/Alert'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
    name: 'OnBoarding',
    components: { SelectOption, InputBox, Alert, VueTelInput },

    setup() {
        const store = useStore()
        const route = useRoute()
        const year = new Date().getFullYear()
        const date = new Date(`${year}-12-31`)
        const newDate = moment(date).format('YYYY-MM-DD')

        const initialState = () => ({
            disableButton: false,
            orgName: '',
            phoneNumber: '',
            orgEmail: '',
            business: '',
            typeOfBusiness: '',
            lastFinAccYear: newDate,
            lead: '',
            bankName: '',
            bankAccountNumber: '',
            bankSortCode: '',
            bankAccountName: '',
            orgCategory: '',
            plan: '',
            period: '',
            isEmailValid: null,
            isValidatingEmail: false,
            noOfStaff: '',
        })

        const state = reactive(initialState())

        const onChange = async (newValue, key) => {
            state[key] = newValue
        }

        const validateEmail = async () => {
            state.isEmailValid = null
            state.isValidatingEmail = true
            await store
                .dispatch('Auth/validateEmail', state.orgEmail)
                .then((resp) => (state.isEmailValid = resp))
                .finally(() => (state.isValidatingEmail = false))
        }

        const onSubmit = () => {
            state.disableButton = true

            const { disableButton, isValidatingEmail, ...data } = state

            const payload = {}
            const dataKeys = Object.keys(data)
            dataKeys.forEach((dataKey) => {
                if (typeof payload[dataKey] === 'string' || payload[dataKey] instanceof String) {
                    payload[dataKey] = data[dataKey]?.trim()
                } else {
                    payload[dataKey] = data[dataKey]
                }
            })

            store.dispatch('Auth/setupOrg', payload).then((resp) => {
                if (resp?.success) {
                    Object.assign(state, initialState())
                    state.disableButton = false
                } else {
                    state.disableButton = false
                }
            })
        }

        onBeforeMount(() => {
            const { plan, period } = route.query
            // const plans = ['micro', 'small', 'medium']
            // const periods = ['monthly', 'yearly']
            // if (!plans.includes(plan) || !periods.includes(period)) {
            //     window.location = 'https://simplebks.com/pricing.html'
            // }
            state.plan = plan || ''
            state.period = period || ''
        })

        return { state, newDate, onChange, validateEmail, onSubmit }
    },
}
</script>

<style>
.input-group {
    position: relative;
}
.input-group-label {
    position: absolute;
    top: 16px;
    right: 15px;
    cursor: pointer;
}
.fa-2x {
    font-size: 1.3em;
}
.form-sign2 {
    padding: 11px 10px;
    font-size: 14px;
    border: 0.25px solid #2b59ff;
    border-radius: 5px;
    background: white;
    width: 100%;
}
.tel-div{
    width: 48%;
}
</style>
